// RackSelection.js
import React from 'react';

const RackSelection = ({ racks, onSelect, onBack }) => {
  return (
    <div>
      <div className="zone-columns">
        {racks.map((rack) => (
          <div key={rack.id} className="zone-column">
            <div className="card zone-card" onClick={() => onSelect(rack)}>
              <div className="card-content">
                <p className="title">{rack.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RackSelection;