// InventorySession.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Peer from 'simple-peer';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useAuth } from '../contexts/AuthContext';
import { useInventory } from '../contexts/InventoryContext';
import { VIDEO_CONSTRAINTS, CODEC } from '../config/videoConfig';
import { jwtDecode } from 'jwt-decode';

const InventorySession = ({ 
  rack, 
  onBack, 
  updateQuantity, 
  sendInventoryUpdate
}) => {
  // Hooks and Context
  const { socket, sendMessage } = useWebSocket();
  const { user } = useAuth();
  const { inventorySessionId, setInventorySessionId, lastActiveSessionId, setLastActiveSessionId } = useInventory();
  const [isSessionActive, setIsSessionActive] = useState(false);

  // State variables
  const [inventoryStarted, setInventoryStarted] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [peer, setPeer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [iceConnected, setIceConnected] = useState(false);
  const [isTorchOn, setIsTorchOn] = useState(false);

  const [torchCapability, setTorchCapability] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const streamRef = useRef(null);

  // State variables for the torch modal
  const [showTorchModal, setShowTorchModal] = useState(false);
  const [torchOnStart, setTorchOnStart] = useState(false);

  // State variables for the updated products
  const [updatedProducts, setUpdatedProducts] = useState({});

  // Refs
  const videoRef = useRef(null);

  // Function to check if a stored session is valid
  const checkStoredSession = useCallback(async (sessionId) => {
    return new Promise((resolve) => {
      const messageHandler = (event) => {
        const data = JSON.parse(event.data);
        console.log('checkStoredSession received:', data);
        if (['session_valid', 'session_not_found', 'session_expired'].includes(data.type)) {
          socket.removeEventListener('message', messageHandler);
          resolve(data.type === 'session_valid');
        }
      };
  
      socket.addEventListener('message', messageHandler);
  
      sendMessage({
        type: 'check_session',
        inventory_session_id: sessionId
      });
  
      // Timeout pour éviter d'attendre indéfiniment
      setTimeout(() => {
        socket.removeEventListener('message', messageHandler);
        resolve(false);
      }, 5000);
    });
  }, [socket, sendMessage]);

  // Function to toggle the torch
  const toggleTorch = async () => {
    if (!streamRef.current || !torchCapability) return;
  
    const track = streamRef.current.getVideoTracks()[0];
    const capabilities = track.getCapabilities();
  
    if (!capabilities.torch) {
      console.log("Torch is not supported on this device");
      return;
    }
  
    try {
      if (isIOS) {
        // Sur iOS, nous devons arrêter et redémarrer le flux pour changer l'état de la torche
        const currentConstraints = track.getConstraints();
        await track.stop();
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            ...currentConstraints,
            advanced: [{ torch: !isTorchOn }]
          }
        });
        streamRef.current = newStream;
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
        }
      } else {
        // Pour les autres plateformes, nous pouvons utiliser applyConstraints
        await track.applyConstraints({
          advanced: [{ torch: !isTorchOn }]
        });
      }
      setIsTorchOn(!isTorchOn);
    } catch (err) {
      console.error("Error toggling torch:", err);
    }
  };

  const turnOffTorch = async () => {
    if (!streamRef.current || !torchCapability) return;

    try {
      if (isIOS) {
        // Sur iOS, nous arrêtons simplement le flux
        streamRef.current.getVideoTracks().forEach(track => track.stop());
      } else {
        const track = streamRef.current.getVideoTracks()[0];
        await track.applyConstraints({
          advanced: [{ torch: false }]
        });
      }
      setIsTorchOn(false);
    } catch (err) {
      console.error("Error turning off torch:", err);
    }
  };

  // Function to start the video stream
  const startVideoStream = useCallback(async (sessionId, torchState) => {
    console.log("Starting video stream with session ID:", sessionId);
    setIsLoading(true);
    setIceConnected(false);
  
    try {
    
      // Set the torch state based on the torchOnStart state
      const constraints = {
        video: {
          ...VIDEO_CONSTRAINTS,
          advanced: torchState ? [{ torch: true }] : []
        },
        audio: false
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;

      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();
      console.log("Video capabilities:", capabilities);

      // Vérifier si la torche est supportée
      if (capabilities.torch) {
        setTorchCapability(true);
        setIsTorchOn(torchState);
      } else {
        setTorchCapability(false);
        setIsTorchOn(false);
      }

      const newPeer = new Peer({
        initiator: true,
        trickle: false,
        stream: stream,
        config: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] }
      });
  
      newPeer.on('signal', data => {
        console.log("Peer signaling:", data);
        if (data.type === 'offer') {
          console.log("Sending offer to backend");
          if (sessionId) {
            console.log("Using inventory session ID:", sessionId);
            sendMessage({
              type: 'offer',
              sdp: data.sdp,
              inventory_session_id: sessionId
            });
          } else {
            console.error('No inventory session ID available');
            setIsLoading(false);
          }
        }
      });
  
      newPeer.on('connect', () => {
        console.log("Peer connection established");
      });
  
      newPeer.on('stream', stream => {
        console.log("Received stream from backend", stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            console.log("Video metadata loaded");
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
              playPromise.then(() => {
                console.log("Video playing");
                setVideoReady(true);
                if (iceConnected) {
                  setIsLoading(false);
                }
              }).catch(e => {
                console.error('Error playing video:', e);
                // Ne pas setIsLoading(false) ici, car la connexion ICE pourrait encore s'établir
              });
            }
          };
        }
      });

      newPeer._pc.oniceconnectionstatechange = () => {
        if (newPeer._pc.iceConnectionState === 'connected' || newPeer._pc.iceConnectionState === 'completed') {
          console.log('ICE connection established');
          setIceConnected(true);
          if (videoReady) {
            setIsLoading(false);
          }
        }
      };
  
      newPeer.on('error', err => {
        console.error('Peer error:', err);
        setIsLoading(false);
        alert('Error in video connection. Please try again.');
      });
  
      newPeer.on('close', () => {
        console.log("Peer connection closed");
        setIsLoading(false);
        setVideoReady(false);
      });
  
      setPeer(newPeer);

    } catch (error) {
      console.error('Error starting video stream:', error);
      alert('Error accessing the camera. Please check your device settings.');
      setIsLoading(false);
    }
  }, [sendMessage]);

  // Function to handle starting the inventory
  const handleStartInventory = async () => {
    console.log("handleStartInventory called");
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }

    setShowTorchModal(true);

  };

  // Function to handle the torch choice confirmation
  const handleConfirmTorchChoice = async () => {
    setShowTorchModal(false);
    setIsTorchOn(torchOnStart); // Update the torch state
    await actualHandleStartInventory(torchOnStart);
  };

  // Function to handle the actual starting of the inventory
  const actualHandleStartInventory = useCallback(async (torchState) => {
    console.log("handleStartInventory called");
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const storedId = localStorage.getItem('inventorySessionId');
      if (storedId) {
        const isValid = await checkStoredSession(storedId);
        if (isValid) {
          console.log("Using stored valid session:", storedId);
          setInventorySessionId(storedId);
          setInventoryStarted(true);
          await startVideoStream(storedId, torchState);
          setIsLoading(false);
          return;
        } else {
          localStorage.removeItem('inventorySessionId');
        }
      }
  
      const decodedToken = jwtDecode(user.token);
      const sub = decodedToken.sub;
  
      console.log("Starting new inventory session");
      console.log('User token:', user.token ? `${user.token.slice(0, 4)}...${user.token.slice(-4)}` : 'No token');
      console.log('User sub:', sub ? `${sub.slice(0, 4)}...${sub.slice(-4)}` : 'No sub');
      console.log('User id:', user.id);
      console.log('User name:', user.name);
  
      // Request a new session ID from the backend
      // Récupérer user_id ou chaîne vide
      const userId = user && user.id ? user.id : '';

      const newSessionId = await new Promise((resolve, reject) => {
        sendMessage({
          type: 'start_inventory_session',
          sub: sub,
          user_id: userId,
          width: VIDEO_CONSTRAINTS.width.ideal,
          height: VIDEO_CONSTRAINTS.height.ideal,
          user_agent: navigator.userAgent,
          codec: CODEC
        });
  
        const messageHandler = (event) => {
          const data = JSON.parse(event.data);
          if (data.type === 'start_inventory_session_response') {
            socket.removeEventListener('message', messageHandler);
            resolve(data.inventory_session_id);
          } else if (data.type === 'start_inventory_session_error') {
            socket.removeEventListener('message', messageHandler);
            reject(new Error(data.message));
          }
        };
  
        socket.addEventListener('message', messageHandler);
      });
  
      console.log("New inventory session created with ID:", newSessionId);
      setInventorySessionId(newSessionId);
      localStorage.setItem('inventorySessionId', newSessionId);
  
      // Wait for the state to update
      await new Promise(resolve => setTimeout(resolve, 0));
  
      // Start the video stream with the torch state
      await startVideoStream(newSessionId, torchState);
      setInventoryStarted(true);
    } catch (error) {
      console.error('Error starting inventory session:', error);
      setIsLoading(false);
      setInventoryStarted(false);
    }
  }, [user, sendMessage, startVideoStream, setInventorySessionId]);
  

  // Function to handle stopping the inventory
  const handleStopInventory = async () => {
    console.log("Stopping inventory session");
    setIsSessionActive(false);

    // Éteindre la torche si elle est allumée
    if (isTorchOn) {
      await turnOffTorch();
    }

    if (peer) {
      peer.destroy();
    }
    
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    setInventoryStarted(false);
    setVideoReady(false);
    setIsLoading(false);
    
    sendMessage({
      type: 'end_inventory_session',
      inventory_session_id: inventorySessionId
    });

    setLastActiveSessionId(inventorySessionId);
    setInventorySessionId(null);
    localStorage.removeItem('inventorySessionId');

    // Turn off the torch if it's on
    if (isTorchOn) {
      await turnOffTorch();
      setIsTorchOn(false);
    }
  };

  // Function to handle quantity changes
  const handleQuantityChange = (shelfId, productName, change) => {
    const key = `${shelfId}-${productName}`;
    
    setQuantities(prev => {
      const newQuantity = Math.max(0, (prev[key] || 0) + change);
      console.log(`Updating quantity for ${productName} on shelf ${shelfId}: ${prev[key]} -> ${newQuantity}`);
      
      // Envoyer le message au backend
      if (socket && socket.readyState === WebSocket.OPEN) {

        const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

        const message = {
          type: 'adjusted_quantity',
          data: {
            shelf_id: shelfId.toString(),
            product_name: productName,
            adjusted_quantity: newQuantity.toString(),
            adjustment_reason: change > 0 ? "User increased quantity" : "User decreased quantity",
            inventory_session_id: sessionIdToUse
          }
        };
  
        // Log du message envoyé
        console.log('Sending adjusted_quantity message to backend:', message);
  
        sendMessage(message);
      } else {
        console.error('WebSocket connection is not established.');
      }
  
      return {
        ...prev,
        [key]: newQuantity
      };
    });
  
    // Supprimer l'appel à updateQuantity
    // updateQuantity(shelfId, productName, change);
  };
  

  // Nettoyage du peer
  useEffect(() => {
    // Pas de code ici, nous utilisons cet effet uniquement pour le nettoyage
  
    return () => {
      // Nettoyage au démontage du composant
      if (peer) {
        peer.destroy();
      }
    };
  }, []); 

  // Effect to initialize quantities
  useEffect(() => {
    const initialQuantities = {};
    rack.shelves.forEach(shelf => {
      shelf.expected_products.forEach(product => {
        initialQuantities[`${shelf.id}-${product.name}`] = 0; // Initialisation à zéro
      });
    });
    setQuantities(initialQuantities);

    const headerSection = document.querySelector('.section.has-background-white');
    if (headerSection) {
      headerSection.classList.add('is-hidden');
    }

    return () => {
      if (headerSection) {
        headerSection.classList.remove('is-hidden');
      }
    };
  }, []); // Exécuter seulement au montage

  // Effect to handle WebSocket messages
  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received message:", data);
        switch(data.type) {

          case 'session_valid':
            // Déjà géré dans checkStoredSession, pas d'action nécessaire ici
            break;

          case 'session_not_found':
          case 'session_expired':
            // Déjà géré dans checkStoredSession, pas d'action nécessaire ici
            break;
          
          case 'start_inventory_session_response':
            console.log('Received inventory session ID:', data.inventory_session_id);
            setInventorySessionId(data.inventory_session_id);
            localStorage.setItem('inventorySessionId', data.inventory_session_id);
            break;

          case 'start_inventory_session_error':
            console.error('Error starting inventory session:', data.message);
            setIsLoading(false);
            // Display an error message to the user
            break;

          case 'answer':
            console.log("Received answer from backend", data);
            if (peer) {
              try {
                peer.signal(data);
              } catch (error) {
                console.error("Error signaling peer with answer:", error);
              }
            } else {
              console.warn("Received answer but peer is not initialized");
            }
            break;

          case 'inventory':
            console.log('Received inventory data:', JSON.stringify(data, null, 2));
            try {
              if (data.data) {
                Object.entries(data.data).forEach(([shelfId, shelfData]) => {
                  if (shelfData.products && typeof shelfData.products === 'object') {
                    Object.entries(shelfData.products).forEach(([productName, productData]) => {
                      const key = `${shelfId}-${productName}`;
                      setQuantities(prev => {
                        const oldCount = prev[key] || 0;
                        const newCount = productData.count;
                        if (oldCount !== newCount) {
                          // Ajouter le produit à updatedProducts pour le clignotement
                          setUpdatedProducts(prevUpdated => ({
                            ...prevUpdated,
                            [key]: true
                          }));
          
                          // Supprimer le clignotement après 500ms
                          setTimeout(() => {
                            setUpdatedProducts(prevUpdated => {
                              const { [key]: _, ...rest } = prevUpdated;
                              return rest;
                            });
                          }, 500);
                        }
                        return {
                          ...prev,
                          [key]: newCount
                        };
                      });
                    });
                  } else {
                    console.error('Unexpected products structure for shelf:', shelfId, shelfData);
                  }
                });
              }
            } catch (error) {
              console.error('Error processing inventory data:', error);
            }
            break;

          default:
            console.log('Unhandled message type:', data.type);
        }
      };

      socket.addEventListener('message', handleMessage);

      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket, setInventorySessionId, peer, updateQuantity]);

  const verifyStoredSession = useCallback(async () => {
    const storedId = localStorage.getItem('inventorySessionId');
    if (storedId) {
      console.log("Retrieved stored inventory session ID:", storedId);
      const isValid = await checkStoredSession(storedId);
      if (isValid) {
        setInventorySessionId(storedId);
        setInventoryStarted(true);
  
        // Démarrer le flux vidéo avec l'état de la torche par défaut
        await startVideoStream(storedId, false);
      } else {
        console.log("Stored session is no longer valid. Clearing local data.");
        setInventorySessionId(null);
        setInventoryStarted(false);
        localStorage.removeItem('inventorySessionId');
      }
    }
  }, [checkStoredSession, startVideoStream, setInventorySessionId]);

  useEffect(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      verifyStoredSession();
    }
  }, [socket, verifyStoredSession]);

  // Effect to detect if the device is iOS
  useEffect(() => {
    // Détecte si l'appareil est iOS
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  useEffect(() => {
    if (inventorySessionId) {
      setIsSessionActive(true);
      setLastActiveSessionId(inventorySessionId);
    }
  }, [inventorySessionId, setLastActiveSessionId]);

  // Render
  return (
    <div className="inventory-session">
      <div className="inventory-header mb-4">
        <button onClick={onBack} className="button is-small is-light">
          <i className="fas fa-chevron-left"></i>
        </button>
        <h2 className="title is-4 ml-2 mb-0">{rack.name} Inventory</h2>
        {/* <button 
          onClick={toggleTorch} 
          className={`button is-small ${isTorchOn ? 'is-warning' : 'is-light'}`}
          disabled={!videoReady}
        >
          <i className={`${isTorchOn ? 'fas' : 'far'} fa-lightbulb`}></i>
        </button> */}
      </div>

      {/* Modal for torch settings */}
      {showTorchModal && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Torch Settings</p>
              <button className="delete" aria-label="close" onClick={() => setShowTorchModal(false)}></button>
            </header>
            <section className="modal-card-body">
              <label className="checkbox">
                <input 
                  type="checkbox" 
                  checked={torchOnStart} 
                  onChange={() => setTorchOnStart(!torchOnStart)} 
                />
                &nbsp; Activate Torch
              </label>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success" onClick={handleConfirmTorchChoice}>Start Inventory</button>
              <button className="button" onClick={() => setShowTorchModal(false)}>Cancel</button>
            </footer>
          </div>
        </div>
      )}

      <div id="videoContainer">
        {isLoading && (
          <div className="spinner-container">
          <svg className="pl" viewBox="0 0 176 160" width="176px" height="160px" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="pl-grad" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="hsl(33,90%,55%)" />
                <stop offset="30%" stopColor="hsl(33,90%,55%)" />
                <stop offset="100%" stopColor="hsl(3,90%,55%)" />
              </linearGradient>
            </defs>
            <g fill="none" strokeWidth="16" strokeLinecap="round">
              <circle className="pl__ring" r="56" cx="88" cy="96" stroke="hsla(0,10%,10%,0.1)" />
              <path className="pl__worm1" d="M144,96A56,56,0,0,1,32,96" stroke="url(#pl-grad)" strokeDasharray="43.98 307.87" />
              <path className="pl__worm2" d="M32,136V96s-.275-25.725,14-40" stroke="hsl(33,90%,55%)" strokeDasharray="0 40 0 44" strokeDashoffset="0.001" visibility="hidden" />
              <path className="pl__worm3" d="M144,136V96s.275-25.725-14-40" stroke="hsl(33,90%,55%)" strokeDasharray="0 40 0 44" strokeDashoffset="0.001" visibility="hidden" />
            </g>
          </svg>
        </div>
        )}
        <video 
          id="remoteVideo" 
          ref={videoRef} 
          className={videoReady ? '' : 'is-hidden'} 
          autoPlay 
          playsInline 
          muted 
        />
      </div>
      
      {!inventoryStarted ? (
        <button 
          onClick={handleStartInventory} 
          className="button is-success is-fullwidth mt-4"
        >
          <span className="icon"><i className="fas fa-play"></i></span>
          <span>Start</span>
        </button>
      ) : (
        <button 
          onClick={handleStopInventory} 
          className="button is-danger is-fullwidth mt-4"
        >
          <span className="icon"><i className="fas fa-stop"></i></span>
          <span>Stop</span>
        </button>
      )}

      <div className="shelves-container mt-4">
        {rack.shelves.map(shelf => (
          <div key={shelf.id} className="mb-4">
            <h3 className="title is-5 mb-2 pb-2 has-border-bottom">{shelf.name}</h3>
            {shelf.expected_products.map(product => (
              <div key={product.name} className="field has-addons mb-2">
                <span className="control is-expanded has-text-left">{product.name}</span>
                <div className="control">
                  <button className="button is-primary is-left" onClick={() => handleQuantityChange(shelf.id, product.name, -1)}>-</button>
                </div>
                <div className="control">
                <input 
                    className={`input has-text-centered count-cell ${updatedProducts[`${shelf.id}-${product.name}`] ? 'is-blinking' : ''}`}
                    type="number"
                    value={quantities[`${shelf.id}-${product.name}`] || 0}
                    onChange={(e) => handleQuantityChange(shelf.id, product.name, parseInt(e.target.value) - (quantities[`${shelf.id}-${product.name}`] || 0))}
                  />    
                  {/* <input 
                    className="input has-text-centered count-cell"
                    type="number"
                    value={quantities[`${shelf.id}-${product.name}`] || 0}
                    onChange={(e) => handleQuantityChange(shelf.id, product.name, parseInt(e.target.value) - (quantities[`${shelf.id}-${product.name}`] || 0))}
                  /> */}
                </div>
                <div className="control">
                  <button className="button is-primary is-right" onClick={() => handleQuantityChange(shelf.id, product.name, 1)}>+</button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <button 
        className="button is-success is-fullwidth mt-4"
        onClick={sendInventoryUpdate}
      >
        <span className="icon"><i className="fas fa-paper-plane"></i></span>
        <span>Send</span>
      </button>
    </div>
  );
};

// PropTypes for type checking (if you're using prop-types)
// import PropTypes from 'prop-types';
// InventorySession.propTypes = {
//   rack: PropTypes.object.isRequired,
//   onBack: PropTypes.func.isRequired,
//   updateQuantity: PropTypes.func.isRequired,
//   sendInventoryUpdate: PropTypes.func.isRequired,
// };

export default InventorySession;