// StorageAreaSelection.js
import React from 'react';

const StorageAreaSelection = ({ storageAreas, onSelect }) => {
  return (
    <div>
      <h2>Select Storage Area</h2>
      <div className="zone-columns">
        {storageAreas.map((area) => (
          <div key={area.id} className="zone-column">
            <div className="card zone-card" onClick={() => onSelect(area)}>
              <div className="card-content">
                <p className="title">{area.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StorageAreaSelection;