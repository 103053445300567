// App.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import './App.css';
import 'bulma/css/bulma.min.css';
import { WebSocketProvider, useWebSocket } from './contexts/WebSocketContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import Navbar from './components/Navbar';
import InventoryNavigation from './components/InventoryNavigation';
import { getKeycloakInstance } from './services/auth';
import { InventoryProvider } from './contexts/InventoryContext';
import { VIDEO_CONSTRAINTS, CODEC } from './config/videoConfig';

const App = () => {
  const { user, authenticated } = useAuth();
  const [userContext, setUserContext] = useState(null);
  const [inventorySessionId, setInventorySessionId] = useState(null);
  const [state, setState] = useState({
    mode: null,
    zone: null,
    rack: null,
    shelf: null,
    showSelectMessage: true,
    showZoneSelection: false
  });
  const { socket, connectWebSocket, sendMessage } = useWebSocket();
  const userContextAttemptsRef = useRef(0);
  const MAX_USER_CONTEXT_ATTEMPTS = 3;

  useEffect(() => {
    if (authenticated && user && !socket) {
      connectWebSocket(user.token);
    }
  }, [authenticated, user, connectWebSocket, socket]);

  const getUserContext = useCallback(() => {
    if (userContextAttemptsRef.current >= MAX_USER_CONTEXT_ATTEMPTS || userContext) {
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN && user && user.token) {
      console.log('Requesting user context...');
      try {
        const decodedToken = jwtDecode(user.token);
        const sub = decodedToken.sub;
        sendMessage({
          type: 'get_user_context',
          sub: sub
        });
        userContextAttemptsRef.current += 1;
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    } else {
      console.error('WebSocket is not open or user is not authenticated. Cannot request user context.');
    }
  }, [socket, user, sendMessage, userContext]);

  useEffect(() => {
    if (authenticated && socket && !userContext) {
      const storedContext = localStorage.getItem('userContext');
      if (storedContext) {
        console.log('Using stored user context');
        setUserContext(JSON.parse(storedContext));
      } else {
        console.log('No stored user context found, requesting from server');
        getUserContext();
      }
    }
  }, [authenticated, socket, getUserContext, userContext]);

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        switch (message.type) {
          case 'user_context':
            console.log('Received user context:', message.data);
            setUserContext(message.data);
            localStorage.setItem('userContext', JSON.stringify(message.data));
            break;
          case 'start_inventory_session_response':
            setInventorySessionId(message.inventory_session_id);
            break;
          case 'user_context_error':
            console.error('Error getting user context:', message.error);
            break;
          default:
            console.log('Unhandled message type:', message.type, message);
            break;
        }
      };

      socket.addEventListener('message', handleMessage);

      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('state') === 'logged-out') {
      // User has been redirected back after logout
      window.history.replaceState(null, '', window.location.pathname);
      // Optionally, you can force a page reload here
      // window.location.reload();
    }
  }, []);

  const startInventorySession = async () => {
    console.log("Starting inventory session - startInventorySession()");

    if (socket && socket.readyState === WebSocket.OPEN && user) {

      const stream = await navigator.mediaDevices.getUserMedia({ video: VIDEO_CONSTRAINTS });
      const track = stream.getVideoTracks()[0];
      const settings = track.getSettings();

      sendMessage({
        type: 'start_inventory_session',
        user_id: user.id,
        width: settings.width,
        height: settings.height,
        user_agent: navigator.userAgent,
        codec: 'vp9',
        token: user.token,
        user_context: userContext
      });
    }
  };

  const handleModeSelect = (mode) => {
    setState(prevState => ({
      ...prevState,
      mode,
      showSelectMessage: mode !== 'rack',
      showZoneSelection: mode === 'rack'
    }));
  };

  const updateBreadcrumb = useCallback(() => {
    const breadcrumbItems = [];
    if (state.mode) {
      breadcrumbItems.push({ text: state.mode, onClick: () => setState({ mode: null, zone: null, rack: null, shelf: null }), isHome: true });
    }
    if (state.zone) {
      breadcrumbItems.push({ text: state.zone.name, onClick: () => setState(prev => ({ ...prev, zone: null, rack: null, shelf: null })) });
    }
    if (state.rack) {
      breadcrumbItems.push({ text: state.rack.name, onClick: () => setState(prev => ({ ...prev, rack: null, shelf: null })) });
    }
    if (state.shelf) {
      breadcrumbItems.push({ text: state.shelf.name });
    }
    
    return (
      <nav id="rackBreadcrumb" className={`breadcrumb has-succeeds-separator is-small ${state.mode ? '' : 'is-hidden'}`} aria-label="breadcrumbs">
        <ul>
          {breadcrumbItems.map((item, index) => (
            <li key={index} className={index === breadcrumbItems.length - 1 ? 'is-active' : ''}>
              {item.onClick ? (
                <button onClick={item.onClick} className="breadcrumb-link">
                  {item.isHome && <span className="icon is-small"><i className="fas fa-home" aria-hidden="true"></i></span>}
                  <span>{item.text}</span>
                </button>
              ) : (
                <span>{item.text}</span>
              )}
            </li>
          ))}
        </ul>
      </nav>
    );
  }, [state]);

  useEffect(() => {
    updateBreadcrumb();
  }, [updateBreadcrumb]);

  if (!authenticated) {
    const keycloak = getKeycloakInstance();
    if (keycloak) {
      keycloak.login();
    }
    return null;
  }

  return (
    <div className="App">
      <section className="section has-background-white">
        <div>
          {/* <h1 className="title is-4" id="title">Welcome {user ? user.name : 'User'}</h1> */}
          
          <div className="columns is-mobile is-multiline is-centered">
            {['periodic', 'product', 'rack', 'free'].map((mode) => (
              <div key={mode} className="column is-one-quarter-mobile">
                <div className={`card ${state.mode === mode ? 'is-selected' : ''}`} onClick={() => handleModeSelect(mode)}>
                  <div className="card-content">
                    <span className="icon card-icon">
                      <i className={`fas fa-${mode === 'periodic' ? 'calendar-alt' : mode === 'product' ? 'box-open' : mode === 'rack' ? 'qrcode' : 'binoculars'}`}></i>
                    </span>
                    <p className="title">{mode.charAt(0).toUpperCase() + mode.slice(1)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {updateBreadcrumb()}
        </div>
      </section>
      <div id="selectMessage" className={`section ${state.showSelectMessage ? '' : 'is-hidden'}`}>
        <div>
          <div className="has-text-centered">
            <span className="icon">
              <i className="fas fa-chevron-up"></i>
            </span>
            <p>Select the inventory mode you want</p>
          </div>
        </div>
      </div>
      <div id="content" className="section">
        {userContext ? (
          state.showZoneSelection && (
            <InventoryNavigation 
              userContext={userContext} 
              state={state} 
              setState={setState}
              startInventorySession={startInventorySession}
              inventorySessionId={inventorySessionId}
            />
          )
        ) : (
          <div>Loading user context...</div>
        )}
      </div>
      <Navbar />
    </div>
  );
};

const AppWithProviders = () => (
  <AuthProvider>
    <WebSocketProvider>
      <InventoryProvider>
        <App />
      </InventoryProvider>
    </WebSocketProvider>
  </AuthProvider>
);

export default AppWithProviders;