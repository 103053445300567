// Navbar.js
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const { logout, user } = useAuth();

  return (
    <nav className="navbar is-fixed-bottom is-white" role="navigation" aria-label="main navigation">
      {/* Navbar Brand */}
      <div className="navbar-brand">
        {/* Logo */}
        <a className="navbar-item" href="https://sananga.tech">
          <img src={process.env.PUBLIC_URL + '/assets/sanangaico.png'} alt="Sananga technology" />
        </a>

        {/* Hamburger menu */}
        <button
          className={`navbar-burger ${isActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded={isActive ? "true" : "false"}
          data-target="navbarMenu"
          onClick={() => setIsActive(!isActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      {/* Navbar Menu */}
      <div id="navbarMenu" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className="navbar-end">
          {/* Message de bienvenue */}
          <div className="navbar-item">
            <p>Welcome {user ? user.name : 'User'}</p>
          </div>
          {/* Bouton Logout */}
          <div className="navbar-item">
            <button className="button is-light" onClick={logout}>
              <span className="icon">
                <i className="fas fa-sign-out-alt"></i>
              </span>
              <span>Logout</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
