// src/services/auth.js
import Keycloak from 'keycloak-js';

let keycloakInstance = null;

export const initKeycloak = () => {
  if (keycloakInstance) {
    return Promise.resolve(keycloakInstance.authenticated);
  }

  keycloakInstance = new Keycloak('/keycloak.json');

  return keycloakInstance
    .init({
      onLoad: 'check-sso', // Utiliser 'check-sso' pour éviter la redirection automatique
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      checkLoginIframe: true, // Activer pour détecter les changements de session
    })
    .then((authenticated) => {
      console.log('Keycloak authenticated:', authenticated);
      return authenticated;
    })
    .catch((error) => {
      console.error('Keycloak init error:', error);
      throw error;
    });
};

export const getToken = () => (keycloakInstance ? keycloakInstance.token : null);

export const updateToken = (minValidity) =>
  keycloakInstance
    ? keycloakInstance.updateToken(minValidity)
    : Promise.reject('Keycloak not initialized');

export const logout = () => {
  if (keycloakInstance) {
    keycloakInstance.clearToken(); // Effacer le token localement
    return keycloakInstance.logout({
      redirectUri: window.location.origin,
    });
  }
  return Promise.reject('Keycloak not initialized');
};

export const getKeycloakInstance = () => keycloakInstance;

export default keycloakInstance;
