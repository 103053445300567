// InventoryNavigation.js
import React from 'react';
import StorageAreaSelection from './StorageAreaSelection';
import RackSelection from './RackSelection';
import InventorySession from './InventorySession';

// import { useAuth } from '../contexts/AuthContext';

const InventoryNavigation = ({ 
  userContext, 
  state, 
  setState, 
  startInventorySession,
  inventorySessionId  
}) => {

  // const { user } = useAuth();

  const handleStorageAreaSelect = (storageArea) => {
    setState({ ...state, zone: storageArea, rack: null, shelf: null });
  };

  const handleRackSelect = (rack) => {
    setState({ ...state, rack, shelf: null });
  };

  const handleBackToStorageAreas = () => {
    setState({ ...state, zone: null, rack: null, shelf: null });
  };

  const handleBackToRacks = () => {
    setState({ ...state, rack: null, shelf: null });
  };

  const updateQuantity = (shelfId, productName, change) => {
    console.log(`Updating quantity for ${productName} on shelf ${shelfId} by ${change}`);
    setState(prevState => {
      const updatedRack = {...prevState.rack};
      const shelf = updatedRack.shelves.find(s => s.id === parseInt(shelfId));
      if (shelf) {
        const product = shelf.expected_products.find(p => p.name === productName);
        if (product) {
          product.current_quantity = (product.current_quantity || 0) + change;
        }
      }
      return {...prevState, rack: updatedRack};
    });
  };

  const sendInventoryUpdate = () => {
    // Implémentez ici la logique pour envoyer les mises à jour d'inventaire au backend
    console.log('Sending inventory update');
    // Vous pouvez ajouter ici la logique pour envoyer les données au backend
  };

  if (state.mode === 'rack' && !state.zone) {
    return (
      <StorageAreaSelection
        storageAreas={userContext.storage_areas}
        onSelect={handleStorageAreaSelect}
      />
    );
  }

  if (!state.zone) {
    return (
      <StorageAreaSelection
        storageAreas={userContext.storage_areas}
        onSelect={handleStorageAreaSelect}
      />
    );
  }

  if (!state.rack) {
    return (
      <RackSelection
        racks={state.zone.racks}
        onSelect={handleRackSelect}
        onBack={handleBackToStorageAreas}
      />
    );
  }

  // Ici, nous sommes sûrs que state.rack est défini
  return (
    <InventorySession
      rack={state.rack}
      onBack={handleBackToRacks}
      startInventorySession={startInventorySession}
      updateQuantity={updateQuantity}
      sendInventoryUpdate={sendInventoryUpdate}
      inventorySessionId={inventorySessionId} 
    />
  );
};

export default InventoryNavigation;