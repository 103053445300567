// InventoryContext.js
import React, { createContext, useState, useContext } from 'react';

const InventoryContext = createContext();

export const InventoryProvider = ({ children }) => {
  const [inventorySessionId, setInventorySessionId] = useState(null);
  const [lastActiveSessionId, setLastActiveSessionId] = useState(null);

  return (
    <InventoryContext.Provider 
      value={{ 
        inventorySessionId, 
        setInventorySessionId, 
        lastActiveSessionId, 
        setLastActiveSessionId 
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export const useInventory = () => {
  const context = useContext(InventoryContext);
  if (context === undefined) {
    throw new Error('useInventory must be used within an InventoryProvider');
  }
  return context;
};