// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { initKeycloak, getToken, getKeycloakInstance } from '../services/auth';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initAuth = async () => {
      try {
        const isAuthenticated = await initKeycloak();
        if (isAuthenticated) {
          const token = getToken();
          const userData = jwtDecode(token);
          setUser({
            name: userData.name || userData.preferred_username,
            email: userData.email,
            token: token,
            sub: userData.sub,
            roles: userData.realm_access?.roles || [],
          });
          setAuthenticated(true);
        } else {
          console.log('User is not authenticated');
          setAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        console.error('Error during Keycloak initialization:', error);
        setError('Failed to initialize authentication');
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  const login = () => {
    const keycloak = getKeycloakInstance();
    if (keycloak) {
      keycloak.login();
    }
  };

  const logout = async () => {
    try {
      const keycloak = getKeycloakInstance();
      if (keycloak) {
        await keycloak.logout({
          redirectUri: window.location.origin,
        });
      } else {
        throw new Error('Keycloak instance not found');
      }
      keycloak.clearToken(); // Effacer le token localement
      setUser(null);
      setAuthenticated(false);
      sessionStorage.clear();
      localStorage.clear();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, authenticated, login, logout, error }}>
      {authenticated ? children : <LoginScreen login={login} error={error} />}
    </AuthContext.Provider>
  );
};

const LoginScreen = ({ login, error }) => (
  <div class="notification is-warning has-text-centered">
    <p>You are not logged in.</p>
    {error && <p class="has-text-danger">Error: {error}</p>}
    <button class="button is-primary mt-4" onClick={login}>Log In</button>
  </div>
);

export const useAuth = () => useContext(AuthContext);
